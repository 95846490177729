body {
  font-family: 'Geist Variable';
  margin: 0;
  padding: 0;
  color: #161616;
  background-color: #fff;
}

.coming-soon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}

.profile-section {
  margin-bottom: 20px;
}

.profile-picture {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.name {
  font-size: 2.5rem;
  margin: 0;
}

.role {
  font-size: 1.2rem;
  color: #777;
}

.social-links {
  display: flex;
  gap: 20px;
}

.social-links a {
  font-size: 1rem;
  color: #0077b5;
  text-decoration: none;
}

.social-links a:hover {
  text-decoration: underline;
}
